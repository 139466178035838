<template>
  <v-navigation-drawer
		v-model="drawerTemp"
		class="grey lighten-3"
		app
		:mini-variant.sync="mini"
		:permanent="$vuetify.breakpoint.mdAndUp"
		:temporary="$vuetify.breakpoint.smAndDown"
		style="z-index:99"
	>
		<v-list class="pt-0">
			<v-list-item class="px-2 primary">
				<v-list-item-avatar v-if="usuario" class="white pr-n1">
					<avatar-view size-avatar="40" store="users" :file-name="userId" size-img="xs" />
					<!-- <v-img :src="usuario.avatar"></v-img> -->
				</v-list-item-avatar>
				<v-list-item-title class="white--text" to="/profile">
					<!-- <router-link to="/profile" style="text-decoration:none"> -->
						<div class="white--text">
							<p class="mb-n1">{{ usuario && usuario.nombre ? usuario.nombre: 'no-name' }}</p>
							<p class="mb-0 caption">{{ usuario && usuario.email ? usuario.email: '....' }}</p>
						</div>
					<!-- </router-link> -->
				</v-list-item-title>
				<v-btn
					v-if="$vuetify.breakpoint.mdAndUp"
					icon
					dark
					@click.stop="mini = !mini"
				>
					<v-icon>fa-chevron-left</v-icon>
				</v-btn>
				<v-btn
					v-else
					dark
					icon
					@click.stop="drawerTemp = false"
				>
					<v-icon>fa-chevron-left</v-icon>
				</v-btn>
			</v-list-item>
			<v-list-item
				v-for="item in menu.filter(e => e.grupo === 'home')"
				:key="item.title"
				link
				:to="item.ruta"
			>
				<v-list-item-icon>
					<v-icon>{{ item.icon }}</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>{{ item.title }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-list-group
				v-for="item in groupMenu"
				v-show="menu.filter(e => e.grupo === item.grupo).length"
				:key="item.grupo"
				v-model="item.active"
				no-action
			>
				<!-- :prepend-icon="item.icon" -->
				<template v-slot:prependIcon>
						<v-icon small>{{ item.icon }}</v-icon>
				</template>
				<template v-slot:appendIcon>
						<v-icon small>fa-chevron-up</v-icon>
				</template>
				<template v-slot:activator>
					<v-list-item-content>
						<v-list-item-title class="subtitle-1" v-text="item.descripcion"></v-list-item-title>
					</v-list-item-content>
				</template>
				<v-list-item
					v-for="child in menu.filter(e => e.grupo === item.grupo)"
					:key="child.name"
					link
					:to="child.ruta"
					class="pl-8"
					:disabled="item.name === 'comprobante' && !aperturaCaja"
				>
					<v-list-item-icon>
						<v-icon x-small>
							{{ child.icon }}
						</v-icon>
					</v-list-item-icon>
					<v-list-item-content class="ml-n8">
						<v-list-item-title class="subtitle-2 font-weight-regular" v-text="child.title"></v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list-group>
		</v-list>
		<template v-if="$vuetify.breakpoint.smAndUp" v-slot:append>
			<div class="pa-2 primary">
				<v-btn dark  text color="primary" :icon="mini"  @click.stop="logOut">
					<v-icon small :left="!mini" color="white">
						fa-arrow-right-from-bracket
					</v-icon>
					<span v-if="!mini" class="white-text">Cerrar Sesión</span>
				</v-btn>
			</div>
		</template>
		<div v-if="$vuetify.breakpoint.xsOnly" class="px-2 py-5 primary">
			<v-btn text color="primary" :icon="mini" @click.stop="logOut">
				<v-icon small :left="!mini" color="white">
					fa-arrow-right-from-bracket
				</v-icon>
				<span v-if="!mini" class="white-text">Cerrar Sesión</span>
			</v-btn>
		</div>
	</v-navigation-drawer>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { getAuth, signOut } from 'firebase/auth'
import AvatarView from '@/components/base/AvatarView.vue'

import { dataGruposDrawer } from '@/persistence/data/dataGruposDrawer'

export default {
	name: 'DrawerMenu',
	components: {
		AvatarView
	},
	props: {
		usuario: {
			type: Object,
			require: true
		},
		rol: {
			type: String,
			require: false,
			default: 'na'
		},
		drawer: {
			type: Boolean,
			require: true
		}
	},
	data: () => ({
    mini: false,
		drawerTemp: false,
    tabMenu: null,
		groupMenu: dataGruposDrawer,
		items: [
			{
				title: 'Dashboard',
				icon: 'fa-chart-simple',
				ruta: '/dashboard',
				name: 'dashboard',
				grupo: 'home'
			},
			{
				title: 'Configuración',
				icon: 'fa-cog',
				ruta: '/configuration',
				name: 'configuration',
				grupo: 'configuracion'
			},
			{
				title: 'Gestion de usuarios',
				icon: 'fa-users',
				ruta: '/users',
				name: 'users',
				grupo: 'usuarios'
			},
			{
				title: 'Roles',
				icon: 'fa-shield',
				ruta: '/rol',
				name: 'rol',
				grupo: 'usuarios'
			},
			{
				title: 'Gestionar cajas',
				icon: 'fa-solid fa-wallet',
				ruta: '/caja/gestionar-caja',
				name: 'gestionar-caja',
				grupo: 'caja'
			},
			{
				title: 'Egreso caja',
				icon: 'fa-money-check-dollar',
				ruta: '/caja/egresos-caja',
				name: 'egresos-caja',
				grupo: 'caja'
			},
			{
				title: 'Clientes',
				icon: 'fa-people-group',
				ruta: '/clientes',
				name: 'clientes',
				grupo: 'crm'
				
			},
			// {
			// 	title: 'Estadísticas',
			// 	icon: 'fa-chart-pie',
			// 	ruta: '/clientes-stats',
			// 	name: 'clientes-stats',
			// 	grupo: 'crm'
				
			// },
      {
				title: 'Inventario',
				icon: 'fa-boxes-stacked',
				ruta: '/inventario',
				name: 'inventario',
				grupo: 'inventario'
			},
			{
				title: 'Ingresos y egresos',
				icon: 'fa-file-invoice',
				ruta: '/ingresos-egresos',
				name: 'ingresos-egresos',
				grupo: 'inventario'
			},
			{
				title: 'Proveedores',
				icon: 'fa-people-carry-box',
				ruta: '/proveedores',
				name: 'proveedores',
				grupo: 'inventario'
			},
			{
				title: 'Categorías',
				icon: 'fa-layer-group',
				ruta: '/categories',
				name: 'categories',
				grupo: 'inventario'
			},
      {
				title: 'Cambiar Impuestos',
				icon: 'fa-percent',
				ruta: '/cambiarimpuestos',
				name: 'cambiarimpuestos',
				grupo: 'inventario'
			},
      {
				title: 'Generar etiquetas',
				icon: 'fa-barcode',
				ruta: '/barcodegenerator',
				name: 'barcodegenerator',
				grupo: 'inventario'
			},
      {
				title: 'Comprobantes',
				icon: 'fa-file-invoice-dollar',
				ruta: '/comprobantes',
				name: 'comprobantes',
				grupo: 'ventas'
			},
      {
				title: 'Comprobante Xml',
				icon: 'fa-file-invoice-dollar',
				ruta: '/comprobantexml',
				name: 'comprobantexml',
				grupo: 'ventas'
			},
      {
				title: 'Nuevo',
				icon: 'fa-receipt',
				ruta: '/comprobante',
				name: 'comprobante',
				grupo: 'home'
			},
      {
				title: 'Recibo',
				icon: 'fa-hand-holding-dollar',
				ruta: '/recibo',
				name: 'recibo',
				grupo: 'home'
			},
      {
				title: 'Cargar',
				icon: 'fa-upload',
				ruta: '/loadcomprobantexml',
				name: 'loadcomprobantexml',
				grupo: 'xml'
			},
      {
				title: 'Procesar',
				icon: 'fa-list-check',
				ruta: '/processcomprobantexml',
				name: 'processcomprobantexml',
				grupo: 'xml'
			},
      {
				title: 'Generales',
				icon: 'fa-chart-simple',
				ruta: '/reportes-generales',
				name: 'reportes-generales',
				grupo: 'reportes'
			},
      {
				title: 'Manifiestos',
				icon: 'fa-chart-pie',
				ruta: '/reportes-manifiestos',
				name: 'reportes-manifiestos',
				grupo: 'reportes'
			},
      {
				title: 'Manifiestos Excel',
				icon: 'fa-regular fa-file-excel',
				ruta: '/excelmanifiestos-reporte1',
				name: 'excelmanifiestos-reporte1',
				grupo: 'reportes'
			},
      {
				title: 'Logística Excel',
				icon: 'fa-regular fa-file-excel',
				ruta: '/excellogistica-reporte1',
				name: 'excellogistica-reporte1',
				grupo: 'reportes'
			},
      // {
			// 	title: 'Item Publicación',
			// 	icon: 'fa-shapes',
			// 	ruta: '/publicacion',
			// 	name: 'publicacion',
			// 	grupo: 'catalogo'
			// },
      {
				title: 'Publicaciones',
				icon: 'fa-shapes',
				ruta: '/publicaciones',
				name: 'publicaciones',
				grupo: 'catalogo'
			},
      {
				title: 'Notificaciones',
				icon: 'fa-bell',
				ruta: '/notificaciones',
				name: 'notificaciones',
				grupo: 'catalogo'
			},
			{
				title: 'Contratos',
				icon: 'fa-file-contract',
				ruta: '/contratos',
				name: 'contratos',
				grupo: 'contratos'
			},
			{
				title: 'Entidades',
				icon: 'fa-building',
				ruta: '/entidades',
				name: 'entidades',
				grupo: 'contratos'
			},
			{
				title: 'Registradores',
				icon: 'fa-street-view',
				ruta: '/registradores',
				name: 'registradores',
				grupo: 'contratos'
			},
			{
				title: 'Planes',
				icon: 'fa-layer-group',
				ruta: '/categories-contratos',
				name: 'categories-contratos',
				grupo: 'contratos'
			},
			{
				title: 'Motivos de inactividad',
				icon: 'fa-layer-group',
				ruta: '/motivos-inactividad',
				name: 'motivos-inactividad',
				grupo: 'contratos'
			},
			{
				title: 'Tipos de referencias',
				icon: 'fa-layer-group',
				ruta: '/tipos-referencias',
				name: 'tipos-referencias',
				grupo: 'contratos'
			},
			{
				title: 'Manifiestos',
				icon: 'fa-file-lines',
				ruta: '/manifiestos',
				name: 'manifiestos',
				grupo: 'ordenes'
			},
			{
				title: 'Receptores',
				icon: 'fa-industry',
				ruta: '/receptores',
				name: 'receptores',
				grupo: 'ordenes'
			},
			{
				title: 'Operarios',
				icon: 'fa-user-nurse',
				ruta: '/operarios',
				name: 'operarios',
				grupo: 'ordenes'
			},
			{
				title: 'Conductores',
				icon: 'fa-user-astronaut',
				ruta: '/conductores',
				name: 'conductores',
				grupo: 'ordenes'
			},
			{
				title: 'Vehículos',
				icon: 'fa-truck-moving',
				ruta: '/vehiculos',
				name: 'vehiculos',
				grupo: 'ordenes'
			},
			{
				title: 'Rutas recorrido',
				icon: 'fa-route',
				ruta: '/rutas-recorrido',
				name: 'rutas-recorrido',
				grupo: 'ordenes'
			},
			{
				title: 'Componentes',
				icon: 'fa-layer-group',
				ruta: '/componentes',
				name: 'componentes',
				grupo: 'ordenes'
			},
			{
				title: 'Motivos de anulación',
				icon: 'fa-layer-group',
				ruta: '/motivos-anulacion',
				name: 'motivos-anulacion',
				grupo: 'ordenes'
			},
			{
				title: 'Personas',
				icon: 'fa-users',
				ruta: '/personas',
				name: 'personas',
				grupo: 'rrhh'
			},
			{
				title: 'Empleados',
				icon: 'fa-user-tie',
				ruta: '/empleados',
				name: 'empleados',
				grupo: 'rrhh'
			},
			{
				title: 'Cargos',
				icon: 'fa-solid fa-users-rays',
				ruta: '/cargos',
				name: 'cargos',
				grupo: 'rrhh'
			},
		],
		rutasMini: ['kiosko']
  }),
  computed: {
		...mapGetters(['userId', "aperturaCaja"]),
		menu () {
			const auxMenu = []
			this.items.map(item => {
				if(this.usuario.rutas.includes(item.name))
					auxMenu.push(item)
			})
			return auxMenu
		}
  },
	watch: {
		drawer (newVal) {
			if (newVal) {
				this.drawerTemp = newVal
				this.mini = false
			}
		},
		drawerTemp (newVal) {
			if (!newVal) {
				this.$emit('update:drawer', false)
			}
		},
		'$vuetify.breakpoint.name' (newVal) {
			if (newVal === 'md') {
				this.mini = true
			}
		},
		'$route.name' (newVal) {
			if (this.rutasMini.includes(newVal)) {
				this.mini = true
			}
		}
	},
	created () {
		this.drawerTemp = this.drawer
	},
	mounted () {
		if (this.$vuetify.breakpoint.name === 'md') {
			this.mini = true
		}
	},
  methods: {
    ...mapActions(['logout']),
    logOut () {
			try {
				const auth = getAuth()
				signOut(auth).then(() => {
					this.logout()
					this.$nextTick(()=>{
						this.$router.push('/')
					})
				})
			} catch {
				this.$nextTick(()=>{
					this.$router.push('/')
				})
			}
    }
  }
}
</script>
<style lang="scss" scoped>
.line--text {
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 1 !important;
  overflow: hidden !important;
  align-content: start !important;
}
.white-text {
	color: white;
}
</style>